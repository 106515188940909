<template>
    <ConfirmDialog />
    <Toast />
</template>

<script>
import { useConfirm } from "primevue/useconfirm"
// import { useToast } from "primevue/usetoast"
export default {
  unmounted() {
    this.emitter.off('confirm', this.confirm)
  },
  mounted() {
    this.emitter.on('confirm', this.confirm)
  },
  setup() {
    const c = useConfirm()
    // const toast = useToast()
    // pi pi-info-circle
    // pi pi-exclamation-triangle
    const confirm = (param) => {
      c.require({
        message: param.message,
        header: param.header,
        icon: param.icon,
        rejectProps: {
          label: '취소',
          severity: 'secondary',
          outlined: true,
        },
        acceptProps: {
          label: '확인'
        },
        // accept: () => {
        //     toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 })
        // },
        // reject: () => {
        //     toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        // }
      })
    }
    return {
      confirm
    }
  }
}
</script>
