<template>
  <div id="loading-screen" v-if="showDialog">
    <div id="loading-wrapper">
      <div id="loading-bar"></div>
      <div id="loading-content">
        <slot></slot>
      </div>
      <div v-if="page==='shop'" class="no-result" >{{ currentWarning }}</div>
      <div v-else-if="page==='store'" class="no-result">{{ currentWarning }}</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    page:String
  },
  setup(props) {
    const shopList = [
      '상품 페이지 생성까지 30초 정도의 시간이 필요합니다.',
      '키워드에 따라서 조회 결과가 없을 수 있습니다.',
      '실패시 vinted.fr에서 직접 검색을 해보세요'
    ]
    const storeList = [
      '상품 페이지 생성까지\n30초 정도의 시간이 필요합니다.',
      '스토어 주소 https://smartstore.naver.com/geek9/',
      '생성 실패시 문의 게시판에 요청해 주세요!'
    ]
    let currentWarning 
    if(props.page === undefined || props.page === '') currentWarning = ref('잠시만 기다려주세요...')
    else if(props.page === 'shop') currentWarning = ref(shopList[0])
    else if(props.page === 'store') currentWarning = ref(storeList[0])

    let warningIndex = 0
    let warningInterval

    const rotateWarning = () => {
      if(props.page === 'shop') {
        warningIndex = (warningIndex + 1) % shopList.length
        currentWarning.value = shopList[warningIndex]
      } else if(props.page === 'store') {
        warningIndex = (warningIndex + 1) % storeList.length
        currentWarning.value = storeList[warningIndex]
      }
    }

    onMounted(() => {
      warningInterval = setInterval(rotateWarning, 2000)
    })

    onUnmounted(() => {
      clearInterval(warningInterval)
    })
    return {
      currentWarning
    }
  },

  methods: {
    closeDialog() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading-wrapper {
  width: 400px;
  height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#loading-bar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-top-color: #FF7F50;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 20px;
}

#loading-content {
  text-align: center;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>