<template>
<Modal style="z-index: 9999999999999999999;">
  <div class="modal regist_modal_main">
    <div class="div_title" style="text-align: left;margin-top:-20px;">
      <span style="color: #ff7f50;">서비스 이용약관</span> 
    </div>
    <div>
      <div>
        <textarea class="regist_textarea margin10">
해외구매(배송대행) 표준약관

제1조(목적) 이 약관은 GEEK9(이하 “회사”)가 제공하는 배송관련 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자 간의 권리·의무, 책임사항 및 절차 등을 규정함을 목적으로 합니다. 

제2조(정의) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

1. “몰”은 회사가 이 약관에 의하여 서비스를 이용자에게 제공하기 위하여 정보통신설비와 정보통신망을 이용하여 서비스 등을 거래할 수 있도록 설정한 가상의 영업장을 의미하거나 “몰”을 운영하는 사업자를 의미합니다.

2. “이용자”라 함은 회사가 제공하는 서비스를 이용하는 자를 의미합니다.

3. “해외사업자”라 함은 대한민국 이외의 국적이거나 대한민국 이외의 국가에 사업자 등록, 법인, 영업소, 호스트서버 소재지 등을 가진 사업자를 의미합니다.

4. “배송대행”이라 함은 이용자가 해외사업자로부터 구매한 재화 등(이하 “운송물”)이 회사가 이용자에게 제공하는 고유의 배송대행지에 입고되면 회사가 국제 운송 및 수입통관을 거쳐 해당 운송물을 이용자가 지정한 국내 수령 장소까지 운송하는 서비스를 의미합니다.

5. “배송대행지”라 함은 이용자가 해외에서 운송물을 구매하고자 할 경우 현지의 운송물 수령 장소로 이용하는 회사 제공의 해외 주소지를 의미합니다.

6. “배송대행신청서”라 함은 배송대행 계약의 체결을 위하여 이용자가 운송물 및 국내 수령 장소 관련 정보 등을 기입하여 회사에 송신하는 문서를 의미합니다.

7. “검수”라 함은 이용자가 배송대행을 의뢰한 운송물의 누락, 하자, 파손 여부 등을 회사가 정한 기준에 따라 확인하는 서비스를 의미합니다.


제3조(서비스의 제공) 회사는 다음과 같은 업무를 수행할 수 있습니다.

1. 서비스 등에 대한 정보제공

2. 이용자가 해외에서 구매한 운송물의 수령, 보관, 검수, 인도

3. 이용자가 해외에서 구매한 운송물에 대한 운송계약의 체결

4. 수입 및 통관 관련 업무

5. 반품, 교환, 환불 등 국제반송 관련 업무

6. 기타 회사가 정하는 업무


제4조(서비스 이용 제한)

① 회사는 이용자의 서비스 이용 요청이 다음 각 호의 어느 하나에 해당하는 경우 서비스 제공을 거절하거나, 이용자의 동의를 구하고(다만, 이용자에게 동의를 구할 수 없는 사정이 있는 경우에는 통지 후) 해당 운송물을 폐기하거나 이용자의 비용 부담으로 해외사업자에게 반송할 수 있습니다.

1. 신청내용에 허위, 기재누락, 오기가 있는 경우

2. 이용자가 요청한 서비스의 제공이 회사의 경영상 또는 기술상의 이유로 현저히 곤란한 경우

3. 이용자가 불법 또는 부당한 목적을 위해 서비스를 이용하는 것으로 판단되는 경우

4. 운송물이 동물, 금·은괴, 화폐, 의약품, 무기류, 인체의 일부, 포르노그래피, 정밀금속, 석재류, 냉장보관물품, 냉동 또는 냉장을 요하는 물품 및 폭발물, 가연성 위험물 등인 경우

5. 운송물이 통과국을 포함한 수출입국의 법령에 의하여 수출입이 금지되는 물품인 경우

6. 운송물이 운송 사업자의 운송 약관상 운송이 금지된 물품인 경우

 

② 전항에 따라 서비스 제공을 거절하는 경우, 회사는 이용자에게 거절의 사유 및 근거를 통지하여야 합니다.

 

③ 이용자가 제1항 각 호에 해당하는 서비스 이용 요청을 하였음에도 불구하고 배송대행신청서에 허위 정보를 기재하여 회사가 과태료, 벌금 등의 손해를 입은 경우, 이용자는 회사의 손해를 배상하여야 합니다.

 

제5조(배송대행신청서)

① 이용자는 배송대행 계약을 체결하는 때에 다음 각 호의 사항을 기재한 배송대행신청서를 작성하여야 합니다.

1. 운송물 수령을 위한 배송대행지

2. 운송물을 구매한 해외 구매처(매장 내지 해외 전자상거래 사이트 등)에 관한 정보

3. 해당 해외 전자상거래 사이트에서 발행한 주문번호와 배송추적번호

4. 상품명, 브랜드, 해외사업자, 품목, 구매가격, 수량, 색상, 사이즈

5. 해외 세금, 해외 배송비, 할인금액

6. 재포장, 추가포장 관련 선택사항, 운송물의 개봉 및 검수에 관한 선택사항

7. 운송물의 국내 수령 장소, 이름(또는 상호) 및 연락처

8. 개인통관고유부호

9. 기타 배송대행에 필요한 사항

 

② 회사는 배송대행계약을 체결하는 때에 이용자에게 다음 각 호의 사항을 설명하여야 합니다.

1. 이용자가 배송대행신청서에 기재한 운송물의 가액이 채무불이행으로 인한 회사의 손해배상책임을 정할 때 손해배상액 산정기준이 된다는 점

2. 운송물의 가액에 따라 할증요금 또는 보험 등이 있는 경우 이에 따라 손해배상한도액에 차이가 있다는 점

3. 운송물 구매계약의 청약철회, 해지 또는 해제(이하 “청약철회 등”)는 이용자가 구매계약을 체결한 해외사업자에게 직접 해야 한다는 점

 

제6조(계약의 성립)

① 회사는 이용자의 배송대행신청이 있는 경우 이용자에게 수신확인통지를 하여야 합니다. 수신확인통지에는 배송대행신청 정보와 신청의 정정, 취소 등에 관한 정보 등을 포함하여야 합니다.

 

② 배송대행 계약은 이용자의 배송대행신청에 대하여 회사의 수신확인통지가 이용자에게 도달한 때에 성립됩니다.

 

③ 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우 지체 없이 회사에 배송대행신청 변경 또는 취소를 요청할 수 있으며, 회사는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 요금을 지급한 경우에는 제13조의 청약철회 등에 관한 규정에 따릅니다.

 

④ 배송대행 계약이 성립하고 이용자가 배송대행을 신청한 운송물이 회사의 배송대행지에 입고되면 회사는 계약에 따른 서비스의 제공을 개시합니다.

 

제7조(배송대행요금 청구와 보관료)

① 이용자는 회사의 배송대행지에 운송물이 도착하기 전까지 “몰”에 배송대행신청서를 송신해야 할 의무가 있습니다.

 

② 배송대행계약이 체결되고 해당 운송물이 회사의 배송대행지에 입고되면 회사는 이용자에게 배송대행요금의 지급을 청구하고 이용자는 지급 청구가 있은 날로부터 7일 이내에 해당 요금을 지급하여야 합니다. 추가 운송료가 발생할 시에는 회사는 이용자에게 사유와 금액을 통지해야 합니다.

 

③ 제2항에 따른 기일까지 배송대행요금이 지급되지 않을 경우 회사는 해당 운송물을 배송하지 않습니다. 이 경우 지급 요청 기간 경과 후부터 이용자가 요금을 지급한 날까지의 기간에 대하여 보관료가 추가로 부과됩니다. 회사는 추가 보관료 부과 사유 및 부과 기준을 사전에 홈페이지 등에 게시하고, 제2항에 따른 배송대행요금 지급청구 시에 이용자에게 구체적으로 명시해야 합니다.

 

제8조(검수)

① 회사는 배송대행계약이 체결되고 배송대행지에 입고된 운송물에 대하여 동일성 여부 및 하자·파손 여부, 운송물이 수출입 법령 등에 저촉되지 않는지 여부 등의 확인을 위해 해당 운송물의 개봉·검수를 할 수 있습니다. 회사는 검수의 기준 및 범위를 정하여 이용자에게 미리 통지합니다.

 

② 회사가 해당 운송물의 하자, 파손 등을 발견한 경우, 이용자에게 이를 통보하고 운송물의 처분에 대한 이용자의 지시에 따릅니다. 회사는 검수의 하자로 인해 이용자가 입은 손해를 배상해야 합니다. 다만, 회사가 제공하는 검수 범위를 벗어난 하자 및 운송물의 성능·기능상의 하자에 대하여는 손해배상책임을 지지 않습니다.

 

③ 회사가 해당 운송물의 검수를 통해 악취, 액체누수 등의 이상이 있다고 판단한 경우 이용자에게 이러한 사실을 통지하고 해당 운송물을 별도 보관하는 등 임시조치를 취할 수 있습니다. 임시 조치 과정상 이용자에게 손해가 발생한 경우 회사의 고의 또는 중과실로 인한 경우를 제외하고 해당 손해에 대하여 회사는 책임을 지지 않습니다.

 

④ 전항의 경우 회사는 이용자의 요구에 따라 해당 운송물을 처분하며, 해당 운송물에 발생한 이상이 회사의 책임 있는 사유로 인해 발생한 때에는 이용자의 손해를 배상하고 처분에 소요되는 비용을 부담합니다. 다만, 해당 운송물에 발생한 사고가 운송물의 성질이나 하자 또는 회사의 책임 없는 사유로 인한 것인 때에는 이용자가 처분에 소요되는 비용을 부담합니다.

 

제9조(재포장 및 추가포장)

① 회사는 제8조에 따라 운송물의 검수 시에 이용자의 동의를 얻어 해당 운송물을 재포장할 수 있습니다.

 

② 회사는 포장이 운송에 적합하지 아니한 때에는 이용자의 동의를 얻어 운송 중 발생될 수 있는 충격의 정도를 고려하여 추가 포장을 할 수 있습니다. 이 과정에서 추가적인 포장비용이 발생할 경우 회사는 이용자에게 추가요금을 청구할 수 있습니다.

 

③ 제1항과 2항의 경우, 회사는 이용자에게 재포장으로 인해 해외사업자에 대한 반품 및 환급 요구가 제한될 가능성이 있음을 통지하여야 합니다.

 

④ 이용자가 제2항의 추가포장에 동의하지 아니하는 경우에는 포장 부적합 또는 포장 불충분으로 인하여 발생한 손해에 대해서는 회사는 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.

 

제10조(운송현황의 게시 및 통지) 회사는 운송물의 입고, 출고, 선적 등 운송현황에 대한 정보를 몰에 게시하고 이용자에게 통지합니다. 다만, 운송물의 운송현황은 해당 시기의 물류상황, 항공운송 상황 등 제반 사정에 의해 달라질 수 있습니다.


제11조(통관)

① 회사는 수입요건을 구비하여 회사가 정한 운송 및 통관업무 위탁사를 통하여 통관절차를 수행합니다. 이 때 발생하는 관세, 부가세 등 제세금은 이용자 또는 수하인이 부담합니다. 이용자가 제세금을 납부하지 아니하면 회사는 해당 운송물을 배송하지 않습니다.

 

② 회사는 수출입국가의 관세법 등 관련법령을 준수하고, 불법운송물을 취급하지 않으며, 분할배송 및 가격허위 신고 등 이용자의 불법행위 요청에는 협조하지 않습니다.

 

③ 회사는 서비스 등이 수출입국가의 관세규정에 따라 통관이 가능한지 여부에 대해서는 보증하지 않습니다.

 

제12조(긴급조치)

① 회사는 운송물에 관하여 이용자의 책임 있는 사유로 인하여 관할관청 등의 관련법령에 근거한 적법한 인도 요구가 있는 경우, 해당 운송물을 관할관청 등에 인도합니다. 이 경우 회사는 지체 없이 이를 이용자에게 통지합니다.

 

② 제1항의 조치로 인하여 이용자가 손해를 입었다고 할지라도 해당 손해에 대하여 회사는 책임을 지지 않으며, 이 경우에도 이용자의 해당 운송물에 관한 서비스 이용 요금 및 관련 비용 등의 지급의무는 존속됩니다.

 

③ 제1항의 관할관청 등이 운송물을 반환한 경우, 회사는 지체 없이 배송대행계약에 따른 배송을 계속해서 이행해야 합니다.

 

제13조(청약철회 등)

① 회사가 제공하는 해외의 배송대행지에 이용자가 구매한 운송물이 입고되어 이용자의 국내 수령 장소로 발송되기 전에 이용자가 배송대행계약의 청약철회 등을 하는 경우, 해당 운송물은 이용자의 요청에 따라 해외의 배송대행지로부터 해외사업자에게 반송되며, 반송에 소요되는 비용은 이용자가 부담합니다.

 

② 운송물이 배송대행지로부터 출고되어 이용자의 국내 수령 장소로 발송된 이후에는, 이용자는 배송대행계약의 청약철회 등을 할 수 없으며, 회사는 해당 운송물을 이용자의 국내 수령 장소까지 배송함으로써 배송대행계약에 따른 이행을 완료합니다. 이때에 이용자는 배송대행요금의 환급을 청구하지 못합니다.

 

③ 운송물이 이용자의 국내 수령 장소까지 배송된 이후, 회사가 이용자의 반송 요청에 따라 반송을 대행할 수 있는 경우에는, 회사는 해당 운송물을 반송 할 수 있습니다. 이 때 국제 반송에 소요되는 비용은 이용자가 부담해야 합니다.

 

④ 회사는 사전에 배송대행계약의 청약철회 등이 제한되는 경우를 이용자가 쉽게 알 수 있는 곳에 표시하는 등의 조치를 하여야 하며, 이와 같은 조치를 취하지 않았다면 이용자의 청약철회 등은 제한되지 않습니다.

 

제14조(손해배상)

① 회사의 배송대행지로부터 이용자가 지정한 국내 수령 장소까지의 운송구간에서 운송물의 분실, 파손 등의 손해가 발생하였을 경우, 회사는 자기 또는 사용인 기타 운송을 위하여 사용한 자가 운송물의 수령, 인도, 보관 기타 운송에 관하여 주의를 게을리 하지 아니하였음을 증명하지 아니하면, 이용자에게 분실, 파손 등으로 인한 손해를 배상해야 합니다. 다만, 운송물의 가액에 따라 할증요금 또는 보험 등이 있는 경우, 이에 따라 손해배상한도액에 차이가 있을 수 있습니다.

 

② 운송물이 분실된 경우 이용자는 해외사업자가 사용한 해외운송인이 발급한 배송추적번호를 회사에 제공하고, 회사는 당해 배송추적번호를 이용하여 재화 등이 회사의 배송대행지에 입고되었음이 확인된 경우에 한하여 손해를 배상합니다.

 

③ 회사는 배송대행신청서에 기재된 운송물의 구매비용, 관·부가세 및 배송대행요금의 합산금액을 한도로 산정된 이용자의 실제 손해를 배상합니다. 다만 운송물의 분실, 파손 등이 회사의 고의 또는 중과실로 인한 경우에는 회사는 모든 손해를 배상하여야 합니다.

 

④ 이용자가 부정확한 정보를 기입하여 손해가 발생한 경우, 회사의 고의 또는 중과실로 인한 경우를 제외하고 배상 책임을 지지 않습니다.

 

⑤ 회사는 배송대행지를 변경할 수 있으며, 배송대행지 이전 및 이후 각각 1개월 이상의 기간을 정하여 이용자에게 그 사실을 통지하고 해당 통지기간이 경과하면, 이용자가 변경 전의 배송대행지 주소로 배송대행을 신청한 운송물의 분실 등 사고에 대하여는 책임을 지지 않습니다.

 

⑥ 회사는 제1항, 제2항, 제5항의 규정에도 불구하고 운송물의 분실, 파손 등이 회사 또는 그의 사용인의 고의 또는 중대한 과실로 인하여 발생한 때에는 손해를 배상합니다.

 

제15조(사고발생시의 운임 등의 환급과 청구)

① 회사는 천재지변 및 기타 불가항력적인 사유에 의하여 발생한 운송물의 분실, 파손 등에 대해서는 손해배상책임을 지지 않습니다.

 

② 회사는 운송물의 분실, 파손 등이 천재지변 및 기타 불가항력적인 사유로 인한 것인 때에는 배송대행요금을 청구하지 못하며, 회사가 이미 배송대행요금 및 기타 요금을 받은 때에는 이를 환급합니다.

 

제16조(책임의 특별소멸사유와 시효)

① 운송물의 누락 또는 파손에 대한 회사의 손해배상책임은 이용자가 운송물을 수령한 날로부터 7일 이내에 그 누락 또는 파손에 대한 사실을 회사에 통지하지 아니하면 소멸합니다.

 

② 회사가 제1항에 따라 손해배상책임을 부담하는 경우에도 이러한 책임은 이용자가 운송물을 수령한 날로부터 1년이 경과하면 소멸합니다. 다만, 운송물이 분실된 경우에는 이용자가 회사의 분실 사실 통지를 수령한 날로부터 기산합니다.

 

③ 제1항과 제2항의 규정에도 불구하고 회사 또는 그 사용인이 운송물의 누락 또는 파손의 사실을 알면서 이를 숨기고 운송물을 인도한 경우에 회사의 손해배상책임은 이용자 또는 배송대행신청서상 수령인이 운송물을 수령한 날로부터 5년간 존속합니다.

 

제17조(분쟁해결)

① 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.

② 회사는 이용자로부터 제출되는 불만사항 및 의견을 다른 사안에 우선하여 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 지체 없이 통지하여야 합니다.

③ 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 이용자 간에 발생한 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

 

제18조(관할법원 및 준거법)

① 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 이용자 간에 발생한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.

② 회사와 대한민국 국민 또는 대한민국에 사무소를 가지는 이용자 간에 발생한 소송의 준거법은 대한민국법으로 합니다. 



제19조(적립금)

① 회사가 정한 기준에 의해 무상으로 지급되며 현금 환불은 불가합니다.

② 회사가 정한 일정 기간이 지나면 미사용 적립금은 소멸될 수 있으며 소멸된 적립금은 복구가 불가합니다.
        </textarea>
      </div>
      <div style="text-align: right;">
        <!-- <input id='check1no' type="checkbox" style="max-width: 15px;" :checked=!check1 @change="changedCheck1()"/>
        <label for="check1no" style="margin-right:5px;">이용약관에 미동의</label> -->
        <input id='check1yes' type="checkbox" style="max-width: 15px;" :checked=check1 @change="changedCheck1()"/>
        <label for="check1yes" style="margin-right:5px;">이용약관에 동의함</label>
      </div>
      <br>    
      <div class="div_title" style="text-align: left;margin-top:-20px;">
        <span style="color: #ff7f50;">개인정보 취급방침</span> 
      </div>
      <div>
        <textarea class="regist_textarea margin10">
GEEK9는 (이하 "회사"로 표기)은 고객의 개인정보를 보호하기 위해 노력하고 있으며 통신비밀보호법, 정보통신망 이용촉진 등의 법률을 준수하고 있습니다.
회사는 개인정보취급방침을 통해 고객이 제공하는 개인정보의 용도와 개인정보보호 조치에 대해 알려드립니다.

본 방침은 2021년 9월 9일부터 시행됩니다.

1. 수집하는 개인정보의 항목 및 수집방법
2. 개인정보의 수집 및 이용목적
3. 개인정보의 보유기간, 제공
4. 개인정보 파기절차 및 방법
5. 이용자 및 법정대리인의 권리와 그 행사방법
6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
7. 개인정보에 관한 민원 서비스

1. 수집하는 개인정보의 항목 및 수집방법
회사는 회원가입, 상담, 서비스 제공 등을 위해 다음과 같은 개인정보를 수집하고 있습니다.

(1) 수집항목
필수입력항목: 닉네임, ID, 비밀번호, 이메일주소
선택입력항목: 사진, 성별, 생일, 홈페이지주소, 자기소개
비입력항목: 브라우저 종류, 운영체제, 검색어, 서비스 이용 및 방문 기록, IP주소, 쿠키

(2) 수집방법
홈페이지, 게시판, 이메일, 이벤트 응모, 전화
비입력항목 수집 도구를 이용한 수집

2. 개인정보의 수집 및 이용목적
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.

(1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
- 콘텐츠 제공, 물품배송 또는 청구서 등 발송, 구매 및 요금 결제, 금융서비스 및 본인 인증

(2) 회원관리
- 회원제 서비스 이용 및 개인 식별, 불량회원의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 연령 확인, 민원 처리, 고지사항 전달

(3) 마케팅 및 광고, 통계 활용
- 새 서비스 개발 및 서비스 제공, 광고 게재, 이벤트, 광고 제공, 정보 제공, 회원의 서비스 이용 통계 작성

3. 개인정보의 보유기간, 제공
(1) 개인정보 제공
회사는 "2. 개인정보의 수집목적 및 이용목적"에서 알린 범위 내에서 사용하며 이용자의 사전 동의 없이는 범위를 초과하여 사용하거나 외부에 공개, 제공하지 않습니다. 단 다음과 같은 경우에는 예외로 합니다.
- 이용자의 동의를 구한 경우
- 적접한 절차에 의한 정부 수사기관의 요청과 같이 법에 의해 필요하다고 판단되는 경우

(2) 보유기간
개인정보는 서비스를 이용하는 동안 원활한 서비스 이용을 위해 계속 보유하나 회원 탈퇴 신청이나 서비스 중단 등의 상황이 발생할 경우 해당 정보를 바로 파기하거나 외부 공개를 막습니다. 단 다음의 경우에는 명시한 기간 동안 보존합니다.

* 보존항목: ID, 생년월일, 이메일주소
* 보존이유: 서비스 이용의 혼선 방지, 불법적 사용자에 대한 관련 기관 수사협조
* 보존기간: 1년

또한 관계법령에 정한 기간 동안 회원정보를 보관할 수 있습니다.

* 보존항목: 서비스 이용기록, 방문 기록, IP 정보
* 보존이유: 통신비밀보호법
* 보존기간: 3개월

4. 개인정보 파기절차 및 방법
회사는 원칙적으로 앞서 말한 '3-(2) 보유기간'에 해당하지 않은 경우 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
- 회원의 탈퇴 또는 서비스 중지가 일어날 경우 해당 정보를 파기합니다.
- 종이에 출력된 정보는 분쇄나 소각의 형태로 파기합니다.
- 전자적 파일이나 코드 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

5. 이용자 및 법정대리인의 권리와 그 행사방법
이용자 및 법정 대리인은 언제든지 자신의 정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다. 혹은 개인정보관리책임자에게 서면, 전화, 이메일로 연락하시면 바로 처리해드립니다.

6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
회사는 서비스 제공을 위해 '쿠키(cookie)'를 사용합니다. 쿠키는 서버가 이용자의 컴퓨터 브라우저에게 보내는 작은 정보로 이용자의 단말기 내 저장장치에 저장됩니다. 회사는 다음의 목적을 위해 쿠키를 사용합니다.

(1) 쿠키의 사용 목적
서비스 이용자나 방문자의 접속 상태 및 기록을 파악하여 좀더 최적화된형 서비스 제공이나 통계 작성에 사용합니다.

(2) 쿠키의 거부
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서 옵션을 설정함으로써 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 쿠키의 저장을 거부할 수 있습니다. 단 쿠키의 저장을 거부할 경우에는 서비스 이용에 불편이 발생할 수 있습니다.

- 설정방법 보기(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 '도구' 선택 > 인터넷 옵션 > 개인정보 순으로 선택

7. 개인정보에 관한 민원 서비스
회사는 고객의 개인정보를 보호하고 개인정보와 관련한 민원을 처리하기 위하여 아래와 같이 개인정보관리책임자를 지정해 개인정보를 관리하고 있습니다. 이용자는 개인정보 관련 민원을 관리자에게 신고할 수 있으며, 회사는 빠르게 조치를 취하거나 답변을 드립니다.

* 개인정보관리책임자 성명:
* 전화번호: 010-8235-0642
* 이메일: withpd@gmail.com

기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

1. 개인분쟁조정위원회 (1336)
2. 정보보호마크인증위원회 (02-580-0533~4)
3. 대검찰청 인터넷범죄수사센터 (02-3480-3600)
4. 경찰청 사이버테러대응센터 (02-392-0330)

사이트에 링크되어 있는 웹사이트에서 개인정보를 수집하는 행위에 대해서는 본 "사이트 개인정보취급방침"이 적용되지 않음을 알려 드립니다.
        </textarea>
      </div>
      <div style="text-align: right;">
        <!-- <input id='check2no' type="checkbox" style="max-width: 15px;" :checked=!check2 @change="changedCheck2()"/>
        <label for="check2no" style="margin-right:5px;">개인정보 취급방침에 동의안함</label> -->
        <input id='check2yes' type="checkbox" style="max-width: 15px;" :checked=check2 @change="changedCheck2()"/>
        <label for="check2yes" style="margin-right:5px;">개인정보 취급방침에 동의함</label>
      </div>
      <br>
    </div>
    <span class="div_center">
      <Button @click="click({type:'close'})" label="가입취소" raised ></Button>
      <Button @click="click({type:'login'})" label="가입완료" raised ></Button>
    </span>
  </div>
</Modal>
</template>
<script>

export default {
  props: {
    loginId:String,
    loginType:String
  },
  data() { 
    return { 
      check1:false,
      check2:false,
    }
  },
  methods: {
    changedCheck1() {
      this.check1 = !this.check1
    },
    changedCheck2() {
      this.check2 = !this.check2
    },
    async click(param) {
      const type = param.type
      if(type === 'login') {
        if(this.check1 === false) alert("이용약관에 동의를 해주셔야 됩니다")
        else if(this.check2 === false) alert("개인정보 취급방침에 동의를 해주셔야 됩니다")
        else {
          await this.api.axiosGql('INSERT_COMMON_USER', {type: 'INSERT_COMMON_USER', id:this.loginId })
          .then( async (res) => {
            if(res.status === 200) {
              this.$emit('registLogin')
              this.$emit('closeRegistModal')
            } else {
              this.emitter.emit("setCookies", {login:false, id:'', nickname:'', img:'', mail:'', comnum:'', comname:''})
              alert('다시 시도해주세요')
            }
          })
          .catch((err) => {
            this.emitter.emit("setCookies", {login:false, id:'', nickname:'', img:'', mail:'', comnum:'', comname:''})
            console.log(err)
          })
        }
      } else if(type === 'close') {
        this.$emit('closeRegistModal')
        if(this.loginType === 'kakao') {
          window.Kakao.API.request({
            url: '/v1/user/unlink',
            success: (res) => {
              if(res !== undefined) {
                alert('가입이 취소되었습니다.')
                this.emitter.emit("setCookies", {login:false, id:'', nickname:'', img:'', mail:'', comnum:'', comname:''})
              }
            },
            fail: (err) => {
              this.emitter.emit("setCookies", {login:false, id:'', nickname:'', img:'', mail:'', comnum:'', comname:''})
              console.log(err)
              alert('가입이 취소되었습니다.')
            }
          })
        } else if(this.loginType === 'google' || this.loginType === 'facebook') {
          this.emitter.emit("setCookies", {login:false, id:'', nickname:'', img:'', mail:'', comnum:'', comname:''})
          alert('가입이 취소되었습니다.')
        }
      }
    }
  }
}
</script>

<style scoped>
</style>